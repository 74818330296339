/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .input {
    @apply appearance-none focus:outline-none bg-gray-100 px-4 py-3 shadow-inner font-semibold text-gray-600 placeholder-gray-300 rounded inline-block w-full;
  }

  .input.is-danger {
    @apply border border-red;
  }

  .btn {
    @apply space-x-4 px-4 py-3 rounded inline-flex items-center justify-center font-semibold transition-colors duration-700 appearance-none focus:outline-none;
  }

  .btn:disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  .btn.has-shadow {
    @apply shadow;
  }

  .btn.has-shadow:hover {
    @apply shadow-md;
  }

  .btn.expanded {
    @apply w-full;
  }

  .btn.is-default {
    @apply bg-gray-100 text-gray-700;
  }

  .btn.is-transparent {
    @apply bg-transparent text-gray-700;
  }

  .btn.is-transparent:hover {
    @apply text-gray-800;
  }

  .btn.is-default:hover {
    @apply bg-gray-200;
  }

  .btn.is-primary {
    @apply bg-primary-500 text-primary-100;
  }

  .btn.is-primary:hover {
    @apply bg-primary-600;
  }

  .btn.is-dark {
    @apply bg-gray-800 text-gray-100;
  }

  .btn.is-dark:hover {
    @apply bg-gray-900;
  }
}
